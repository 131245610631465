<template>
  <div>
    <div class="content-body">
      <section id="dashboard-ecommerce">
        <div class="row match-height">
          <div class="col-xl-12 col-md-12 col-12">
            <div class="card card-congratulation-medal">
              <div class="card-header">
                <div class="col-xl-12 col-md-12 col-12 d-flex">
                  <div class="col-4"></div>
                  <div
                    class="col-4 align-items-end d-flex justify-content-center"
                  >
                    <h4 class="d-flex text-center">{{ showDate }}</h4>
                  </div>
                  <div class="col-4">
                    <button
                      class="btn btn-primary btn-next week_button float-end"
                    >
                      <vue-feather
                        type="chevron-left"
                        class="align-middle ms-sm-25 ms-0 pre"
                        @click="pre"
                      ></vue-feather>
                      <span class="align-middle d-sm-inline-block week"
                        >Week</span
                      >
                      <vue-feather
                        type="chevron-right"
                        class="align-middle ms-sm-25 ms-0 next"
                        @click="next"
                      ></vue-feather>
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row mb-1">
                  <div
                    class="demo-inline-spacing text-center justify-content-center"
                    v-if="loader"
                  >
                    <div class="spinner-grow text-primary me-1" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                  <div class="main_row col-12 d-flex" v-else>
                    <div
                      class="week_div"
                      v-for="(week, index) in list"
                      :key="week"
                    >
                      <div class="card week-card">
                        <div class="card-header week-card-header">
                          <span>{{
                            dateName(index) + " " + dayName(index)
                          }}</span>
                        </div>
                        <!-- eslint-disable -->
                        <div
                          class="card-body week-card-body"
                          v-for="(item, ind) in list[index]"
                          :key="ind"
                          v-if="list[index] && list[index].length > 0"
                        >
                          <div
                            :class="!item.type ? 'villa' : ''"
                            :id="item.id"
                            :data-bs-toggle="!item.type ? 'modal' : ''"
                            :data-bs-target="
                              !item.type ? '#exampleModalCenter' : ''
                            "
                            @click="villAsign(item)"
                          >
                            <h4 class="card-title text-left card-title-week">
                              {{ fullname(item.villa_detail) }}
                            </h4>
                            <div class="card-text">
                              <div class="card-person-div">
                                <a :href="'tel:' + item.villa_detail.mobile">{{
                                  item.villa_detail.mobile
                                }}</a>
                                <span
                                  class="badge rounded-pill float-end"
                                  :class="getStatus(item)[1]"
                                  >{{ getStatus(item)[0] }}</span
                                >
                              </div>
                              <div
                                class="card-person-div"
                                v-for="villType in list[index][ind].villa_detail
                                  .villa_type_detail"
                                :key="villType.id"
                              >
                                <span class="person_name"
                                  >{{ villType.villa_name
                                  }}{{
                                    item.villa_detail &&
                                    item.villa_detail.villa_detail
                                      ? " (" +
                                        item.villa_detail.villa_detail.number +
                                        ")"
                                      : ""
                                  }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- eslint-enable -->
                        <div class="card-body week-card-body" v-else>
                          <div class="nodata">
                            <span>No Booking </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        class="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalCenterTitle">
                Villa Assign
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div
                class="demo-inline-spacing text-center justify-content-center"
                v-if="villaLoader"
              >
                <div class="spinner-grow text-primary me-1" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              <div v-else>
                <table class="w-100">
                  <tr>
                    <td>Check In :</td>
                    <td width="50px;"></td>
                    <td>
                      <strong>{{ response.check_in_date }}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Check Out :</td>
                    <td width="50px;"></td>
                    <td>
                      <strong>{{ response.check_out_date }}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Amount:</td>
                    <td width="50px;"></td>
                    <td>
                      <strong
                        >₹
                        {{
                          parseFloat(response.final_amount).toFixed(2)
                        }}</strong
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>Payment Mode:</td>
                    <td width="50px;"></td>
                    <td><strong>Online</strong></td>
                  </tr>
                  <tr>
                    <td>Primary Person</td>
                    <td width="50px;"></td>
                    <td>
                      <table>
                        <div
                          v-for="primary in response.villa"
                          :key="primary.id"
                        >
                          <div>
                            <!--v-if="primary.is_primary_user"-->
                            <tr>
                              <td class="p-0">Proof:</td>
                              <td>
                                <img
                                  :src="primary.id_proof_url"
                                  v-if="primary.id_proof_url"
                                  alt="profile"
                                  width="100"
                                />
                                <h6 style="color: red" v-else>
                                  No Proof Available
                                </h6>
                              </td>
                            </tr>
                            <tr>
                              <td class="p-0">Name:</td>
                              <td>
                                <strong>{{ fullname(primary) }}</strong>
                              </td>
                            </tr>
                            <tr>
                              <td class="p-0">Contact:</td>
                              <td>
                                <strong>{{ primary.mobile }}</strong>
                              </td>
                            </tr>
                          </div>
                        </div>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>Villa Assign:</td>
                    <td width="50px;"></td>
                    <td style="position: relative">
                      <span
                        style="font-size: 12px; color: red; font-style: italic"
                        >{{ response.villa_alert_msg }}</span
                      >
                      <br />
                      <div>
                        <div style="position: relative; display: inline-grid">
                          <button
                            v-for="villa_assign in response.villa_name"
                            :key="villa_assign.id"
                            @click="
                              villaDeside(
                                villa_assign.id,
                                response.check_in_date,
                                response.check_out_date
                              )
                            "
                            class="btn btn-outline-success btn-sm mb-1 waves-effect"
                            type="button"
                          >
                            {{ villa_assign.text }}
                          </button>
                          {{
                            response.villa_detail &&
                            response.villa_detail.villa_detail
                              ? " (" +
                                response.villa_detail.villa_detail.number +
                                ")"
                              : ""
                          }}
                        </div>

                        <div class="villa_list" v-show="isVillaList">
                          <span class="close" @click="isVillaList = false"
                            >x</span
                          >
                          <div
                            class="demo-inline-spacing text-center justify-content-center"
                            v-if="villaListLoader"
                          >
                            <div
                              class="spinner-grow text-primary me-1"
                              role="status"
                            >
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </div>
                          <ul v-else>
                            <!-- eslint-disable -->
                            <li
                              v-for="villa in villaList"
                              :key="villa.id"
                              v-if="villaList && villaList.length > 0"
                              :class="
                                activeLi.includes(villa.id) ? `activeLi` : `Li`
                              "
                              @click="
                                selectedVillaNo(
                                  villa.id,
                                  villa.number,
                                  villa.vill_type.villa_name,
                                  activeLi.includes(villa.id)
                                    ? `activeLi`
                                    : `Li`
                                )
                              "
                            >
                              {{ villa.number }}
                            </li>
                            <li v-else>No Villa Available</li>
                            <!-- eslint-enable -->
                          </ul>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr
                    v-for="(selectedList, index) in selectedVillaList"
                    :key="index"
                    v-show="isClick && selectedVillaList[index].length > 0"
                  >
                    <td class="text-success">{{ index }}</td>
                    <td width="50px;"></td>
                    <td>
                      <span
                        v-for="vl in selectedVillaList[index]"
                        :key="vl.id"
                        class="badge bg-primary"
                        style="margin-right: 5px"
                        v-show="activeLi.includes(vl.id)"
                      >
                        {{ vl.no }}&nbsp;
                        <span @click="villaCancel(vl.id)" class="villaCancel"
                          >x</span
                        >
                      </span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                @click="submitVilla"
                :data-bs-dismiss="modalDismis ? `modal` : ``"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "bkDashboard",
  data() {
    return {
      today: new Date(),
      response: "",
      list: [],
      from_date: "",
      to_date: "",
      token: localStorage.getItem("auth_token"),
      id: null,
      vill_type_id: null,
      showDate: null,
      loader: false,
      villaLoader: false,
      villaListLoader: false,
      villaList: [],
      isVillaList: false,
      selectedVillaList: [],
      isClick: false,
      activeLi: [],
      villaId: null,
      modalDismis: false,
      temp_villa: {
        id: null,
        no: null,
      },
    };
  },
  async mounted() {
    this.from_date = moment(this.today).startOf("week").format("YYYY-MM-DD");
    var t = new Date(this.from_date);
    this.to_date = moment(t.setDate(t.getDate() + 6)).format("YYYY-MM-DD");
    this.showDate =
      moment(this.from_date).format("MMM DD, YYYY") +
      " - " +
      moment(this.to_date).format("MMM DD, YYYY");

    this.getResults();
    this.allVilla();
  },
  methods: {
    async getResults() {
      this.loader = true;
      await axios
        .post(
          process.env.VUE_APP_API_URL + "/dashboard",
          {
            from_date: this.from_date ? this.from_date : "2022-12-26",
            to_date: this.to_date ? this.to_date : "2023-01-01",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          console.log("response: ", response);
          this.loader = false;
          this.list = response.data.data;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.go("/login");
            localStorage.removeItem("auth_token");
            localStorage.removeItem("auth_name");
          }
        });
    },
    async allVilla() {
      await axios
        .get(process.env.VUE_APP_API_URL + "/dashboard/villa-type-master", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          this.selectedVillaList = response.data.data;
        });
    },
    dateName(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    dayName(date) {
      return moment(date).format("ddd");
    },
    async pre() {
      var f = new Date(this.from_date);
      this.to_date = moment(f.setDate(f.getDate() - 1)).format("YYYY-MM-DD");
      var t = new Date(this.to_date);
      this.from_date = moment(t.setDate(t.getDate() - 6)).format("YYYY-MM-DD");
      this.showDate =
        moment(this.from_date).format("MMM DD, YYYY") +
        " - " +
        moment(this.to_date).format("MMM DD, YYYY");
      this.getResults();
    },
    async next() {
      var t = new Date(this.to_date);
      this.from_date = moment(t.setDate(t.getDate() + 1)).format("YYYY-MM-DD");
      var f = new Date(this.from_date);
      this.to_date = moment(f.setDate(f.getDate() + 6)).format("YYYY-MM-DD");
      this.showDate =
        moment(this.from_date).format("MMM DD, YYYY") +
        " - " +
        moment(this.to_date).format("MMM DD, YYYY");
      this.getResults();
    },
    getStatus(item) {
      if (item.status == 1 || item.user.user_type == "Owner") {
        return ["Complete", "badge-light-success"];
      } else if (item.status == 2) {
        return ["Pending", "badge-light-warning"];
      } else if (item.status == 3) {
        return ["Cancel", "badge-light-danger"];
      } else if (item.status == 4) {
        return ["Failed", "badge-light-primary"];
      } else if (item.status == 0) {
        return ["Running", "badge-light-info"];
      }
    },
    async villAsign(item) {
      this.modalDismis = false;
      this.villaId = item.id;
      this.isClick = false;
      this.activeLi = [];
      this.selectedVillaList = [];
      this.villaList = [];
      this.allVilla();
      // if ((item.status = 1)) {
      //   this.villaLoader = true;
      //   await axios
      //     .get(process.env.VUE_APP_API_URL + "/villa-show/" + item.id, {
      //       headers: {
      //         "Content-Type": "application/json",
      //         Accept: "application/json",
      //         Authorization: `Bearer ${this.token}`,
      //       },
      //     })
      //     .then((response) => {
      //       this.villaLoader = false;
      //       console.log("res: ", response);
      //       this.response = response.data.data;
      //     });
      // }
    },
    async villaDeside(id, check_in_date, check_out_date) {
      this.villaListLoader = true;
      await axios
        .post(
          process.env.VUE_APP_API_URL + "/villa-deside",
          {
            id: id,
            check_in_date: check_in_date,
            check_out_date: check_out_date,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data[0]);
          this.isVillaList = true;
          this.villaListLoader = false;
          this.villaList = response.data[0];
        });
    },
    selectedVillaNo(id, no, villa, cls) {
      this.isClick = true;
      if (cls == "Li") {
        this.temp_villa = { id: id, no: no };
        const selected = this.selectedVillaList[villa].find((x) => x.no == no);
        if (!selected) {
          this.selectedVillaList[villa].push({ id: id, no: no });
        }
        this.activeLi.push(id);
        this.isVillaList = false;
      }
    },
    async submitVilla(e) {
      e.preventDefault();
      if (this.activeLi.length == 0) {
        this.$toast.error("Please Select Villa number");
      } else {
        await axios
          .post(
            process.env.VUE_APP_API_URL + "/villa-submit/" + this.villaId,
            {
              villa: this.activeLi,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this.token}`,
              },
            }
          )
          .then((response) => {
            if (response.data.code == 201) {
              this.$toast.success(response.data.message);
              this.activeLi = [];
              this.selectedVillaList = [];
              this.villaList = [];
              this.modalDismis = true;
              this.getResults();
            } else {
              this.$toast.error(response.data.error?.[0]);
            }
          })
          .catch((error) => {
            this.$toast.error(error);
          });
      }
    },
    async villaCancel(id) {
      let updateActiveLi = this.activeLi.filter((el) => el !== id);
      this.activeLi = updateActiveLi;
    },
    villaStatus(status) {
      if (status == 1) {
        return `villa`;
      } else if (status == 3) {
        return `cancel_villa`;
      }
    },
    fullname(item) {
      var first_name = item.first_name;
      var middle_name = item.middle_name ? " " + item.middle_name : "";
      var last_name = item.last_name ? " " + item.last_name : "";

      return first_name + middle_name + last_name;
    },
  },
};
</script>

<style scoped>
.week_button {
  display: flex;
  align-items: center;
}

.week {
  text-align: center;
  margin: 0 10px;
}

.main_row {
  width: 100%;
  display: flex;
}

.week_div {
  width: 14%;
  margin: 5px;
  /* border-radius: 20px; */
  text-align: center;
  font-weight: 600;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
}

.week-card {
  /* border-radius: 20px !important; */
  border: none !important;
  margin-bottom: 0 !important;
}

a {
  font-weight: 300 !important;
}

a:hover {
  font-weight: 300 !important;
  text-decoration: underline;
}

.week-card-header {
  background: linear-gradient(97deg, #7da741 62.6%, #dcf1be 135.36%) !important;
  /* background: linear-gradient(118deg, #7367f0, rgba(115, 103, 240, 0.7)) !important; */
  /* border-top-left-radius: 20px;
	border-top-right-radius: 20px; */
  justify-content: center;
  padding: 5px !important;
  color: #fff !important;
}

.week-card-body {
  padding: 0px !important;
}

.card-title-week {
  font-size: 1.1rem !important;
  margin: 10px 0 5px 0;
  background: #b3adf11f;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-top: 1px solid #7da741;
  /* border-top: 1px solid #7367f0; */
  padding: 5px;
}

.card-person-div {
  font-weight: normal;
  text-align: left;
  padding: 5px 10px;
}

.person_name {
  font-weight: bold;
}

.villa {
  box-shadow: 0px 6px 20px 5px rgb(185 182 218 / 21%);
}

.villa:hover {
  box-shadow: inset 4px -7px 20px 4px rgb(185 182 218 / 21%);
  cursor: pointer;
}

.nodata {
  padding: 25px !important;
}

.nodata span {
  font-weight: 100;
}

.pre:hover,
.next:hover {
  background-color: #fff;
  color: #7da741;
  /* color: #7367f0; */
  border-radius: 4px;
}

td {
  padding: 5px;
  border-bottom: 1px solid #eeecf9;
}

.villa_list ul {
  padding-left: 5px;
  margin-bottom: 0;
}

.villa_list ul li {
  list-style: none;
  padding: 2px 10px;
  border-radius: 5px;
}

.Li:hover {
  background-color: #e3e0ff;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}

.villa_list {
  position: absolute;
  padding: 5px 10px;
  height: auto;
  max-height: 200px;
  min-height: 80px;
  width: 45%;
  transition: height 4s;
  background-color: #fff;
  box-shadow: -1px 4px 13px 0px #9e9e9e8a !important;
  border-radius: 5px;
  right: unset !important;
  top: 25px;
  overflow-y: auto;
  z-index: 9999999;
  padding: 20px;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
    margin: 1.75rem auto;
  }
}

.modal-content {
  min-height: 600px;
  height: auto;
}

.close:hover {
  background-color: unset !important;
  color: #000;
  width: 17px;
  height: 22px;
  transition: all 0.5s;
  transform: unset !important;
}

.close {
  float: right;
  margin-top: -20px !important;
  margin-right: -15px !important;
  width: 15px;
  height: 21px;
  padding: 3px 3px 3px 4px;
  cursor: pointer;
  font-weight: 700;
  background-color: #fff;
  color: #000;
  box-shadow: none !important;
}

.activeLi {
  background-color: #999999;
  color: #000;
  cursor: not-allowed;
  margin: 2px auto;
}

.villaCancel:hover {
  cursor: pointer;
  font-weight: bold;
}

.success_villa {
  background-color: rgba(40, 199, 111, 0.12);
  pointer-events: none;
  cursor: default;
}

.cancel_villa {
  background-color: rgba(234, 84, 85, 0.12);
  pointer-events: none;
  cursor: default;
}
</style>
